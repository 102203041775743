<template>
  <div class="p-md-2 w-100">
    <div class="questionnaire-container pt-3">
      <div class="questionBox">

        <b-row class="my-2 d-flex justify-content-center">
          <b-col md="6" sm="12">
            <h3 class="text-center">Member login</h3>

            <div class="px-2">
              <!-- form -->
              <validation-observer ref="loginValidation">
                <b-form class="auth-login-form mt-2" @submit.prevent>
                  <!-- email -->
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false : null"
                        name="login-email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between"></div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!--
                  <b-row class="justify-content-center">
                    <p class="text-center mb-1">
                      <span>Forgot Password? </span>
                      <b-link :to="{ name: 'forgot-password' }" class="mhc-link">
                        <span>Reset Password</span>
                      </b-link>
                    </p>
                  </b-row>
                  -->
                  <!-- submit buttons -->
                  <b-button
                    :disabled="btnDisabled"
                    type="submit"
                    variant="primary"
                    block
                    @click="validationForm"
                  >
                    LOGIN
                  </b-button>
                  <div
                    v-if="loading"
                    class="d-flex justify-content-center mt-2"
                  >
                    <b-spinner variant="primary" label="Loading..." />
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
      btnDisabled: false,
      slugFlag: false,
      slugString: ""
    };
  },
  mounted: function () {
    if(localStorage.getItem("questionnaireSlug") != null && localStorage.getItem("questionnaireSlug") != "") {
      this.slugFlag = true;
      this.slugString = localStorage.getItem("questionnaireSlug");
    }

    //If Session Expired
    if(this.$route.query.sessionExp == 1) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Session Expired',
          icon: "AlertCircleIcon",
          variant: "info",
        },
      });
    }
  },
  computed: {
    userData(userData) {
      store.state.app.userData = userData;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    userData: {
      get(){
        return this.userData
      },
      set(newName){
        return newName
       } 
      }
  },
  methods: {
    updateUserData(userData) {
      store.commit("app/setUserData", userData);
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.btnDisabled = true;
        
          this.$http.post("/api/admin-login", {
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              let userData = response.data;
              try {
                let name = userData.name.split(" ").join("+");
                let user_image = "https://eu.ui-avatars.com/api/?name=" + name;
                userData.avatar = user_image; 
              } catch (err) {
                userData.avatar = "";
              }
              
              this.updateUserData(userData);
              this.userData = userData;
              this.loading = false;
              this.btnDisabled = false;                
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successful Login",
                  icon: "CheckSquareIcon",
                  variant: "success",
                },
              });

              // Redirect to Dashboard
              this.$router.push("/");
            })
            .catch((error) => {
              this.loading = false;
              this.btnDisabled = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.error,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
              this.$refs.loginValidation.setErrors(error.response.data.error);
            });
          
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
